<div class="shell-sidebar" [ngClass]="{ 'collapsed': !expanded }">
  <a [routerLink]="['/tasks']" class="vale-logo"></a>

  <ul>
    <li class="tasksBlock">
      <a [routerLink]="['/'+routesEnum.TASKS]"><app-svg-icon *ngIf="taskPage" icon="icon_clipboard" [current]="true" stroke="#007e7a"></app-svg-icon>
        <app-svg-icon *ngIf="!taskPage" icon="icon_clipboard" [current]="false" stroke="#969696" ></app-svg-icon>
        <span [ngClass]="{ 'current-item': taskPage }">{{ 'side-menu.tasks' | translate}}</span></a>
    </li>
    <li class="title-block title-gestion"><span>{{ 'side-menu.title-licensing' | translate}}</span></li>
    <li class="projects">
      <a [routerLink]="['/'+routesEnum.PROJECT]">
        <app-svg-icon *ngIf="projectsPage" icon="file_folder-icon" [current]="true" stroke="#007e7a"></app-svg-icon>
        <app-svg-icon *ngIf="!projectsPage" icon="file_folder-icon" [current]="false"></app-svg-icon>
        <span [ngClass]="{ 'current-item': projectsPage }">{{ 'side-menu.projects' | translate}}</span>
      </a>
    </li>

    <li class="requisitions">
      <a [routerLink]="['/'+routesEnum.REQUISITIONS]">
        <app-svg-icon *ngIf="requisitionsPage" icon="icon_folder_approved" [current]="true" fill="#007e7a"></app-svg-icon>
        <app-svg-icon *ngIf="!requisitionsPage" icon="icon_folder_approved" [current]="false" fill="#969696"></app-svg-icon>
        <span [ngClass]="{ 'current-item': requisitionsPage }">{{'side-menu.requisitions' | translate}}</span>
      </a>
    </li>

    <li class="authorizing-acts">
      <a [routerLink]="['/'+routesEnum.AUTHORIZING_ACTS]">
        <app-svg-icon *ngIf="authorizingActsPage" icon="icon_license" [current]="true"  fill="#007e7a"></app-svg-icon>
        <app-svg-icon *ngIf="!authorizingActsPage" icon="icon_license" [current]="false" fill="#969696"></app-svg-icon>
        <span [ngClass]="{ 'current-item': authorizingActsPage }">{{'side-menu.authorizing-acts' | translate}}</span>
      </a>
    </li>

    <li class="enviromental-studys">
      <a [routerLink]="['/'+routesEnum.ENVIROMENTAL_STUDYS]">
        <app-svg-icon *ngIf="enviromentalStudysPage" icon="icon_forest" [current]="true"  fill="#007e7a"></app-svg-icon>
        <app-svg-icon *ngIf="!enviromentalStudysPage" icon="icon_forest" [current]="false" fill="#969696"></app-svg-icon>
        <span [ngClass]="{ 'current-item': enviromentalStudysPage }">{{'side-menu.enviromental-studys' | translate}}</span>
      </a>
    </li>

    <li class="technical-analysis">
      <a [routerLink]="['/'+routesEnum.TECHNICAL_ANALYSIS]">
        <app-svg-icon *ngIf="technicalAnalysisPage" icon="icon_review_info" [current]="true"  fill="#007e7a"></app-svg-icon>
        <app-svg-icon *ngIf="!technicalAnalysisPage" icon="icon_review_info" [current]="false" fill="#969696"></app-svg-icon>
        <span [ngClass]="{ 'current-item': technicalAnalysisPage }">{{'side-menu.technical-analysis' | translate}}</span>
      </a>
    </li>

    <li class="comunications">
      <a [routerLink]="['/'+routesEnum.COMMUNICATION]">
          <app-svg-icon icon="icon_mail_inout" *ngIf="!communicationsPage" [current]="false" fill="#969696"></app-svg-icon>
          <app-svg-icon icon="icon_mail_inout" *ngIf="communicationsPage" [current]="true" fill="#007e7a"></app-svg-icon>
          <span  [ngClass]="{ 'current-item': communicationsPage }">{{ 'side-menu.comunications' | translate}}</span>
      </a>
    </li>

    <li class="documents" >
      <a (click)="gotoDocuments()">
      <app-svg-icon *ngIf="documentsPage" icon="icon_folder_box" [current]="true" stroke="#007e7a"></app-svg-icon>
      <app-svg-icon *ngIf="!documentsPage" icon="icon_folder_box" [current]="false"></app-svg-icon>
        <span [ngClass]="{ 'current-item': documentsPage }">{{ 'side-menu.documents' | translate}}</span>
      </a>
    </li>

    <li class="help-center">
      <a [routerLink]="['/'+routesEnum.HELP_CENTER]">
        <app-svg-icon *ngIf="helpPage" icon="help-center" [current]="true" fill="#007e7a"></app-svg-icon>
        <app-svg-icon *ngIf="!helpPage" icon="help-center" [current]="false" fill="#969696"></app-svg-icon>
        <span [ngClass]="{ 'current-item': helpPage }">{{ 'side-menu.help-center' | translate}}</span></a>
    </li>

    <li class="title-block title-insights disabled-item">
      <a class="disabled-item">
        <span style="margin-left: -10px;">{{ 'side-menu.title-insights' | translate}}</span>
      </a>
    </li>

    <li class="disabled-item" *ngIf="true">
      <a (click)="gotoDashboards()">
        <app-svg-icon icon="dashboards-icon" fill="#969696"></app-svg-icon>
        <span>{{ 'side-menu.dashboards.title' | translate}}</span>
        <!-- <span class="comingSoon"
          style="position: fixed; left: 178px"
          nz-tooltip
          nzTooltipPlacement="bottomRight"
          nzTooltipTitle="{{ 'side-menu.text-coming-soon' | translate}}">
          EM BREVE
        </span> -->
      </a>
    </li>

    <!-- <li class="help-center">
      <a [routerLink]="['/'+routesEnum.NLP]">
        <app-svg-icon *ngIf="nlpPage" icon="find_file-icon" [current]="true" fill="#007e7a"></app-svg-icon>
        <app-svg-icon *ngIf="!nlpPage" icon="find_file-icon" [current]="false" fill="#969696"></app-svg-icon>
        <span [ngClass]="{ 'current-item': nlpPage }">{{ 'side-menu.analisys' | translate}}</span></a>
    </li> -->

    <li class="smart-docs" *ngIf="showSmartDocs">
      <a [routerLink]="['/'+routesEnum.SMART_DOCS]">
        <app-svg-icon *ngIf="smartDocsPage" icon="smart_docs-icon" [current]="true" fill="#007e7a"></app-svg-icon>
        <app-svg-icon *ngIf="!smartDocsPage" icon="smart_docs-icon" [current]="false" fill="#969696"></app-svg-icon>
        <span [ngClass]="{ 'current-item': smartDocsPage }">{{ 'side-menu.smart-docs' | translate}}</span></a>
    </li>

  </ul>
</div>
