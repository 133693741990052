import { Component, EventEmitter, inject, OnInit, Output } from "@angular/core";
import { NzModalRef } from "ng-zorro-antd/modal";
import { AttachmentsAndFoldersModel, AttachmentsModel } from "src/app/shared/models/attachments.model";
import { AttachmentsService } from "src/app/shared/services/attachments.service";
import { ProjectsV2Service } from "src/app/shared/services/projects-v2.service";
import { ChatAiService } from "../../service/chat-ai.service";
import { AlertMessageService } from "src/app/shared/services/alert-message.service";
import { NgrxAiUtilsService } from "../../service/ngrx-ai/ngrx-ai-utils.service";
import { IAttachment } from "src/app/shared/ngrx/chat-ai/pages.model";

@Component({
  selector: 'app-file-selection',
  standalone: false,
  templateUrl: './file-selection.component.html',
  styleUrl: './file-selection.component.scss'
})
export class FileSelectionComponent implements OnInit {
  @Output() filesSelection: EventEmitter<AttachmentsAndFoldersModel[]> = new EventEmitter<AttachmentsAndFoldersModel[]>();
  readonly #modal = inject(NzModalRef);
  
  projectList = [];
  selectedProject = null;
  projectAttachments: AttachmentsAndFoldersModel[] = [];
  selectedFiles: AttachmentsAndFoldersModel[] = [];

  constructor(
    private readonly projectService: ProjectsV2Service,
    private readonly attachmentService: AttachmentsService,
    private readonly chatAiService: ChatAiService,
    private ngrxAiUtilsService: NgrxAiUtilsService,
    private alertMessageService: AlertMessageService,
  ) { }

  ngOnInit(): void {
    this.projectService.getProjects(200, 0, null).subscribe({
      next: (response) => {
        this.projectList = response.content;
      }
    })
  }

  defineSelectedProject($event): void {
    this.selectedProject = $event.value;
    this.getDocumentsFileList(this.selectedProject);
  }

  getDocumentsFileList(project): void {
    this.attachmentService.getAllAttachmentsForProject(project.projectId).subscribe({
      next: (attachments) => {
        this.projectAttachments = attachments;
        this.createTreeSelectionNodes(attachments)
      }
    });
  }

  createTreeSelectionNodes(node: AttachmentsAndFoldersModel[]): void {
    node.forEach(innerNode => {
      innerNode.label = innerNode.name
      innerNode.key = innerNode.id.toString()
      
      if (innerNode.children) {
        this.createTreeSelectionNodes(innerNode.children);
      }
    })
  }

  checkSelectedFiles($event): void {
    this.selectedFiles = $event.filter(selected => (selected.name.includes(".pdf") || selected.name.includes(".doc")))
  }

  selectFiles() {
    this.filesSelection.emit(this.selectedFiles);
    const attachments: IAttachment[] = this.selectedFiles.map(file => ({
      attachment: undefined,
      mandatoryDoc: undefined,
      id: file.id,
      name: file.name
    })) 

    this.ngrxAiUtilsService.pushAttachmentListOnStore(attachments);
    this.closeModal();
  }

  closeModal() {
    this.#modal.close();
  }
}