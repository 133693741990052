import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { Feedback, IChat, IMessage } from '../../../interface/commons';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SelectFilesListComponent } from '../select-files-list/select-files-list.component';
import { ChatAiService } from '../../../service/chat-ai.service';
import { IAttachment } from 'src/app/shared/ngrx/chat-ai/pages.model';
import { AlertMessageService } from 'src/app/shared/services/alert-message.service';
import { feedbackComponent } from '../feedback/feedback.component';
import { IfeedbackModalSendEmail, IstatusModalSendEmail } from '../modal-confirm-send-email/modal-confirm-send-email.component';

@Component({
  selector: 'app-conversation-chat-ia',
  templateUrl: './conversation-chat-ia.component.html',
  styleUrl: './conversation-chat-ia.component.scss'
})
export class ConversationChatAIComponent implements OnInit, OnChanges {

  @ViewChild(SelectFilesListComponent) appSelectFilesListView!: SelectFilesListComponent;
  @ViewChild('feedbackComponent') feedbackComponent!: feedbackComponent;

  @Output() sendFilesStatus: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  @Output() sendEmail: EventEmitter<IstatusModalSendEmail> = new EventEmitter<IstatusModalSendEmail>();
  @Output() currentChat: EventEmitter<IChat> = new EventEmitter<IChat>();

  @Input() messages: IMessage[] = [];
  @Input() chatId: string;
  @Input() alreadySendingFiles: boolean = false;
  @Input() showModalSendEmail: boolean = false;
  @Input() feedbackEmail: IfeedbackModalSendEmail | null;

  talkValue: string | undefined;
  form: UntypedFormGroup;
  loggedUser;
  isSendingFiles: boolean = false;
  isPooling: boolean = false;
  isModalSendEmail = false;
  isRestarting: boolean = false;

  countAttachmentSelected: number = 0;

  openSelectionMessageField: boolean = true;

  showFeedbacks: boolean = false;
  feedbacks: Feedback[] = [];
  feedbackContents: string[] = [
    'Feedback registrado ;)',
    'Resposta copiada para a área de transferência',
    'A resposta está incorreta',
    'A resposta está incompleta',
    'Excesso de detalhes',
    'Falta de detalhes importantes',
    'Muita lentidão para dar a resposta',
    'Não respondeu o que perguntei',
    'A referência do documento está errada',
    'Respondeu algo que não existe nos documentos',
    'Outro motivo'
  ];

  constructor(
    private readonly fb: UntypedFormBuilder,
    private chatAiService: ChatAiService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['alreadySendingFiles']?.currentValue) {
      this.isSendingFiles = true;
    } else {
      this.isSendingFiles = false;
      this.form?.get('talk')?.enable();
      if (this.hasHumanMessage() && !this.isRestarting) {
        this.openSelectionMessageField = false;
      }
      this.isRestarting = false;
    }
  }

  ngOnInit() {
    const userSession = sessionStorage.getItem('loggedUser');
    this.loggedUser = userSession ? JSON.parse(userSession) : null;

    this.initForm();
  }

  initForm() {
    this.form = this.fb.group({
      talk: ['', [Validators.minLength(3)]],
    });
  }

  restartChat() {
    this.openSelectionMessageField = true;
    this.isSendingFiles = false;
    this.isRestarting = true;
    this.callAction('attachments')
    if (this.appSelectFilesListView) {
      this.appSelectFilesListView.hasTouchedSetAttachment = false;
    }
  }

  hasHumanMessage(): boolean {
    let messageUsers = this.messages.find((message) => message.role === 'human');
    return !!messageUsers;
  }

  callAction(action: string, message?: IMessage): void {
    switch (action) {
      case 'attachments':
        this.appSelectFilesListView?.verifyIfExistAttachment();
        break;
      case 'send':
        this.talkValue = this.form.get('talk')?.value;

        if(this.form.valid && !!this.talkValue) {
          this.form.setValue({ talk: "" })
          this.messages.push({
            "chat_id": this.chatId,
            "content": this.talkValue,
            "message_id": Math.random().toString(),
            "role": "human"
          });

          const request = {
            "chat_id": this.chatId,
            "content": this.talkValue,
            "target_files": []
          }
          this.chatAiService.callChatAiEndpoint(request, `/chats/ask/?username=${this.loggedUser.email}`).subscribe({
            next: (response) => {
              this.messages.push(response)
              this.onEmitCurrentChatBySendEmail(response);
            }
          })
        } else{
          this.form.get('talk')?.setErrors({ invalid: true });
        }
        break;
      case 'copy':
        if (message) {
          this.storeCopy(message);
        }
        break;
      case 'like':
        if (message) {
          this.sendFeedBack(message, message.content, true);
        }
        break;
      case 'deslike':
        if (message) {
          this.setListNegativeFeedback(message);
        }
        break;
    }
  }

  onEmitCurrentChatBySendEmail(response: any) {
    this.currentChat.emit({ chat_id: response?.chat_id, name: response?.name, username: this.loggedUser?.username });
  }

  onChangeSelectedValue(event: IAttachment[]) {
    this.countAttachmentSelected = event.length;
  }

  textSentToAi(event: IMessage) {
    this.openSelectionMessageField = false;
    this.isSendingFiles = true;
    this.sendFilesStatus.emit(true);
    this.messages.push(event);
  }

  storeCopy(message: IMessage): void {
    navigator.clipboard.writeText(message.content || '').catch(err => {
      console.error('Could not copy message: ', err);
    });
    this.updateMessageWithFeedback(this.foundMessageIndex(message), message, this.feedbackContents[1]);
  }

  sendFeedBack(message: IMessage, content: string, isPositive: boolean): void {
    const requestBody = {
      content: isPositive ? '' : content,
      is_positive: isPositive
    };

    this.chatAiService.callChatAiEndpoint(requestBody, `/chats/feedback?message_id=${message.message_id}`).subscribe({
      next: (response: IMessage) => {
        const foundMessageIndex = this.foundMessageIndex(message);
        if (foundMessageIndex !== -1) {
          this.updateMessageWithFeedback(foundMessageIndex, response, this.feedbackContents[0]);
        } else {
          console.log('Message not found');
        }
      }
    });
  }

  updateMessageWithFeedback(index: number, response: IMessage, content: string): void {
    this.messages[index] = response;
    this.messages[index]._showFeedback = true;
    this.showMessage(index, content);
  }

  showMessage(index: number, content: string): void {
    this.messages[index]._feedbacks = [{
      content: content,
      closeAction: true
    }];

    setTimeout(() => {
      this.messages[index]._showFeedback = false;
      this.clearFeedbacks(index);
    }, 5000);
  }

  clearFeedbacks(index: number): void {
    this.messages[index]._feedbacks = [];
  }

  foundMessageIndex(message: IMessage): number {
    return this.messages.findIndex(msg => msg.message_id === message.message_id);
  }

  setListNegativeFeedback(message:IMessage): void {

    const messageIndex = this.messages[this.foundMessageIndex(message)];

    messageIndex._showFeedback = true;

    messageIndex._feedbacks = this.feedbackContents
      .filter((feedback, index) => index !== 0 && index !== 1)
      .map(feedback => {
        return {
          content: feedback,
          closeAction: false
        };
      });
  }

  onCloseModal(event: IstatusModalSendEmail) {
    this.sendEmail.emit(event);
  }

}
