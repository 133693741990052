import { createReducer, on } from '@ngrx/store';
import { setAttachments } from './chat-ai.actions';
import { AttachmentsState } from './chat-ai.state.model';

const initialState: AttachmentsState = {
  attachments: []
};

const _attachmentReducer = createReducer(
  initialState,
  on(setAttachments, (state, { attachments }) => ({ ...state, attachments }))
);

export function AttachmentReducer(state, action) {
  return _attachmentReducer(state, action);
}
