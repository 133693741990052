<div class="app-start-chat-ai">

    @if (chatsAllResponse.chats.length === 0 || !chatsAllResponse)
    {
        <div class="wellcome__content mb__60">
            <div class="icon">
                <img src="assets/icons/icon_ai_particles_purple24.svg"/>
            </div>
            <div class="text__info">
                <h3>{{'chat-ia.start-chat.title' | translate}}</h3>
                <span>{{'chat-ia.start-chat.subtitle' | translate}}</span>
            </div>
        </div>

        <div class="footer">
            <app-button-icon-primary
                [id]="'btn_new__chat'"
                [text]="'Nova conversa'"
                (click)="newChatClick()">
            </app-button-icon-primary>
        </div>
    }

    @if (chatsAllResponse.chats.length > 0)
    {
        <div class="historic__content">
            <div class="text__info">
                <h3>{{'chat-ia.start-chat.info.title' | translate}}</h3>
                <span>{{'chat-ia.start-chat.info.subtitle' | translate}}</span>
            </div>

            <div class="container__scroll">
                @for (item of chatsAllResponse.chats; track item)
                {
                    <div class="historic__options">
                        <div class="historic__row">
                            <div class="icon">
                                <img src="assets/icons/speechbubble_purple32.svg"/>
                                <img src="assets/icons/icon_ai_particles_dark_purple24.svg"/>
                            </div>

                            <div class="text" (click)="onRowClick(item)">
                                <span>{{ item.name }}</span>
                                <img src="assets/icons/arrow_link_gray10.svg"/>
                            </div>
                        </div>

                    </div>
                }
            </div>

            <div class="footer">
                <app-button-icon-primary
                    [id]="'btn_new__chat'"
                    [text]="'Nova conversa'"
                    (click)="newChatClick()">
                </app-button-icon-primary>
            </div>
        </div>


    }
</div>
