import { Component, Input } from '@angular/core';
import { CardTitle } from '../../../interface/chat-ia-view';
import { ChatAiService } from '../../../service/chat-ai.service';

@Component({
  selector: 'app-card-title',
  standalone: false,
  templateUrl: './card-title.component.html',
  styleUrl: './card-title.component.scss'
})
export class CardTitleComponent {
  
  @Input() configCardTitle: CardTitle = {
    title: 'Pergunte à IA',
    subtitle: 'Use a IA para encontrar informações rapidamente.',
    editable: false
  };

  isEditing: boolean = false;

  constructor(
    private chatAiService: ChatAiService
  ) {}

  onFocusOut() {
    this.isEditing = false;
  }

  onKeyDown($event) {
    if ($event.key === 'Enter' || $event.key === 'Escape') {
      this.isEditing = false;
      this.editChatName();
    }
  }

  onEditTitle() {
    if (this.configCardTitle.editable) {
      this.isEditing = true;
      setTimeout(() => {
        const inputElement = document.getElementById('focusInputTitle') as HTMLInputElement;
        if (inputElement) {
          inputElement.focus();
          inputElement.click();
        }
      }, 100);
    }
  }

  editChatName() {
    if (this.configCardTitle.editable && this.configCardTitle.id) {
      this.chatAiService.callChatAiEndpoint(null, `/chats?chat_id=${this.configCardTitle.id}&chat_name=${this.configCardTitle.title}`)
      .subscribe({
        next: (response) => {}
      })
    }
  }
}
