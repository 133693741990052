import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class FileValidationService {
  // Lista de extensões de arquivo proibidas
  private forbiddenExtensions = [
    'exe'
  ];

  private forbiddenForIA = [
    'doc', 'docx', 'pdf', 'zip', 'gz', 'rar', '7z', 'tar', 'tar.gz', 'tar.z', 'tar.bz2', 'tar.xz', 'tgz'
  ];

  private onlyPDF = [
    'pdf'
  ];

  private forbiddenForGeo = [
    'shp', 'shx', 'dbf', 'prj', 'dwg', 'sbn', 'sbx', 'fbn', 'fbx', 'ain', 'aih', 'ixs', 'mxs', 'atx', 'shp','xml', 'cpg', 'qix', 'qpj', 'fix', 'lyr', 'dat', 'map', 'e00'
  ];

  public isExtensioOnlyPDF(fileName: string): boolean {
    const extension = fileName.includes('.') ? fileName.slice(fileName.lastIndexOf('.') + 1) : '';
    return this.onlyPDF.includes(extension.toLowerCase());
  }

  public isExtensionForbidden(fileName: string): boolean {
    const extension = fileName.includes('.') ? fileName.slice(fileName.lastIndexOf('.') + 1) : '';
    return this.forbiddenExtensions.some(forbidden => extension.toLowerCase().includes(forbidden))
  }

  public isExtensionForIA(fileName: string): boolean {
    const extensionForIA = fileName.includes('.') ? fileName.slice(fileName.lastIndexOf('.') + 1) : '';
    return this.forbiddenForIA.includes(extensionForIA.toLowerCase());
  }

  public isExtensionForGeo(fileName: string): boolean {
    const extensionForGeo = fileName.includes('.') ? fileName.slice(fileName.lastIndexOf('.') + 1) : '';
    return this.forbiddenForGeo.includes(extensionForGeo.toLowerCase());
  }

  public allowedExtensions (fileName: string, screenForbiddenExtensions : string[]): boolean {
    const extension = fileName.includes('.') ? fileName.slice(fileName.lastIndexOf('.') + 1) : '';
    return screenForbiddenExtensions.includes(extension.toLowerCase());
  }

  isZipFile(file: File): Promise<boolean> {

    return new Promise<boolean>((resolve, reject) => {

      const reader = new FileReader();
      reader.onload = (event) => {
        const arrayBuffer = reader.result as ArrayBuffer;
        const uint8Array = new Uint8Array(arrayBuffer);

        if (uint8Array.length > 4 &&
          uint8Array[0] === 0x50 &&
          uint8Array[1] === 0x4B && 
          uint8Array[2] === 0x03 && 
          uint8Array[3] === 0x04    
      ) {
          // Verifica se o arquivo é um .xlsx
          const isXlsx = uint8Array.length > 30 &&
              uint8Array[30] === 0x6D && 
              uint8Array[31] === 0x61 &&
              uint8Array[32] === 0x6E && 
              uint8Array[33] === 0x66 &&
              uint8Array[34] === 0x65;

          resolve(isXlsx);
      } else {
          resolve(false); // Não é um arquivo ZIP
      }

      };

      reader.onerror = (error) => {
        reject(error);
      };

      // Leitura dos primeiros bytes do arquivo
      reader.readAsArrayBuffer(file.slice(0, 10));
    });
  }


  isExeFile(file: File): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const arrayBuffer = reader.result as ArrayBuffer;
        const uint8Array = new Uint8Array(arrayBuffer);

        // Verifica se os primeiros bytes correspondem aos bytes mágicos de um arquivo EXE
        if (uint8Array.length > 1 &&
            (uint8Array[0] === 77 && uint8Array[1] === 90)) {
          resolve(true);
        } else {
          resolve(false);
        }
      };

      reader.onerror = (error) => {
        reject(error);
      };

      // Leitura dos primeiros 2 bytes do arquivo
      reader.readAsArrayBuffer(file.slice(0, 2));
    });
  }

}
