<div nz-row class="container">
  <div nz-col>
    <h1>Central de Ajuda</h1>
  </div>
</div>
<div nz-row [nzGutter]="[16, 16]" class="container video-scroll">
  <div nz-col [nzSpan]="8" class="gutter-row">
    <div class="video-card">
      <div class="video-mini" (click)="showVideo('Cadastro RIPLA',
       'https://stkeycloak.blob.core.windows.net/videos/CadastroRipla.mp4?sp=r&st=2024-07-10T18:37:45Z&se=2024-12-21T02:37:45Z&spr=https&sv=2022-11-02&sr=b&sig=kzCzOV0vkrDrGzQ39cVpdtdlOg0PGe9g8d17UC9rWgE%3D')">
        <img src="/assets/video/sample.png" alt="Cadastro RIPLA" style="max-width: 100%;"/>
        <div class="video-shadow">
        </div>
        <div class="video-play-icon">
          <img src="/assets/icons/play.svg" alt="tocar"/>
        </div>
      </div>
      <div class="video-body">
        <h2>Cadastro RIPLA</h2>
      </div>
    </div>
  </div>

  <div nz-col [nzSpan]="8" class="gutter-row">
    <div class="video-card">
      <div class="video-mini" (click)="showVideo('Análise da RIPLA',
       'https://stkeycloak.blob.core.windows.net/videos/AnaliseRipla.mp4?sp=r&st=2024-07-10T18:37:14Z&se=2024-12-21T02:37:14Z&spr=https&sv=2022-11-02&sr=b&sig=grplwxlliNDHKWtSZKvD82F3pVvel%2FR0L7lNuXm05Pw%3D')">
        <img src="/assets/video/sample.png" alt="Analise RIPLA" style="max-width: 100%;"/>
        <div class="video-shadow">
        </div>
        <div class="video-play-icon">
          <img src="/assets/icons/play.svg" alt="tocar"/>
        </div>
      </div>
      <div class="video-body">
        <h2>Análise da RIPLA</h2>
      </div>
    </div>
  </div>

  <div nz-col [nzSpan]="8" class="gutter-row ">
    <div class="video-card">
      <div class="video-mini" (click)="showVideo('Análise dos Especialistas',
       'https://stkeycloak.blob.core.windows.net/videos/AnaliseEspecialistas.mp4?sp=r&st=2024-07-10T18:31:43Z&se=2024-12-21T02:31:43Z&spr=https&sv=2022-11-02&sr=b&sig=aKCMcEdW8zolTP%2BCZ14sUp8ArKEopJLKiqcAeIoRImc%3D')">
        <img src="/assets/video/sample.png" alt="Analise Especialista" style="max-width: 100%;"/>
        <div class="video-shadow">
        </div>
        <div class="video-play-icon">
          <img src="/assets/icons/play.svg" alt="tocar"/>
        </div>
      </div>
      <div class="video-body">
        <h2>Análise dos Especialistas</h2>
      </div>
    </div>
  </div>

  <div nz-col [nzSpan]="8" class="gutter-row">
    <div class="video-card">
      <div class="video-mini" (click)="showVideo('Documentos',
       'https://stkeycloak.blob.core.windows.net/videos/Documentos.mp4?sp=r&st=2024-07-10T18:38:12Z&se=2024-12-21T02:38:12Z&spr=https&sv=2022-11-02&sr=b&sig=fWRuyl39v4yW9b1bR%2FP0z3FKT3kS04YsbiB%2FSi1PXUY%3D')">
        <img src="/assets/video/sample.png" alt="Cadastro Ripla" style="max-width: 100%;"/>
        <div class="video-shadow">
        </div>
        <div class="video-play-icon">
          <img src="/assets/icons/play.svg" alt="tocar"/>
        </div>
      </div>
      <div class="video-body">
        <h2>Documentos</h2>
      </div>
    </div>
  </div>

  <div nz-col [nzSpan]="8" class="gutter-row">
    <div class="video-card">
      <div class="video-mini" (click)="showVideo('Publicação do parecer final da RIPLA',
       'https://stkeycloak.blob.core.windows.net/videos/PublicacaoParecerRIPLA.mp4?sp=r&st=2024-07-10T18:39:06Z&se=2024-12-21T02:39:06Z&spr=https&sv=2022-11-02&sr=b&sig=36TF8TJjTHRzPbOGxrtn%2FWNDrUhH%2BW63HSmxChdm0Xc%3D')">
        <img src="/assets/video/sample.png" alt="Publicação do parecer final da RIPLA" style="max-width: 100%;"/>
        <div class="video-shadow">
        </div>
        <div class="video-play-icon">
          <img src="/assets/icons/play.svg" alt="tocar"/>
        </div>
      </div>
      <div class="video-body">
        <h2>Publicação do parecer final da RIPLA</h2>
      </div>
    </div>
  </div>

  <div nz-col [nzSpan]="8" class="gutter-row">
    <div class="video-card">
      <div class="video-mini" (click)="showVideo('Navegação Geral do Sistema',
       'https://stkeycloak.blob.core.windows.net/videos/NavegacaoGeralSL-1.mp4?sp=r&st=2024-07-10T18:38:38Z&se=2024-12-21T02:38:38Z&spr=https&sv=2022-11-02&sr=b&sig=u9%2B8cqivKw9zT4uhw6%2FGqe02Q3C3S51r1Boa51VteTw%3D')">
        <img src="/assets/video/sample.png" alt="Cadastro Ripla" style="max-width: 100%;"/>
        <div class="video-shadow">
        </div>
        <div class="video-play-icon">
          <img src="/assets/icons/play.svg" alt="tocar"/>
        </div>
      </div>
      <div class="video-body">
        <h2>Navegação Geral do Sistema</h2>
      </div>
    </div>
  </div>

  <div nz-col [nzSpan]="8" class="gutter-row">
    <div class="video-card">
      <div class="video-mini" (click)="showVideo('Como abrir chamados no VSC para acessar ao Smart Licensing',
       'https://stkeycloak.blob.core.windows.net/videos/ReportarProblemasVSC.mp4?sp=r&st=2024-07-15T12:50:30Z&se=2024-12-20T20:50:30Z&spr=https&sv=2022-11-02&sr=b&sig=jFH0Yc6%2F2iUWVy62o2nf5qH1Ye4rPleip8KJ8DpxuTI%3D')">
        <img src="/assets/video/sample.png" alt="Como abrir chamados no VSC para acessar ao Smart Licensing" style="max-width: 100%;"/>
        <div class="video-shadow">
        </div>
        <div class="video-play-icon">
          <img src="/assets/icons/play.svg" alt="tocar"/>
        </div>
      </div>
      <div class="video-body">
        <h2>Como abrir chamados no VSC para acessar ao Smart Licensing</h2>
      </div>
    </div>
  </div>

  <div nz-col [nzSpan]="8" class="gutter-row">
    <div class="video-card">
      <div class="video-mini" (click)="showVideo('Inserir um link para o Anexo IV',
       'https://stkeycloak.blob.core.windows.net/videos/CriacaoAnexo4.mp4?sp=r&st=2024-12-03T15:11:52Z&se=2025-12-31T23:11:52Z&spr=https&sv=2022-11-02&sr=b&sig=EPyEChC314zdNNMkl3Ia5jVnC2l9%2FfDMebAH1ysXF2M%3D')">
        <img src="/assets/video/sample.png" alt="Inserir um link para o Anexo IV" style="max-width: 100%;"/>
        <div class="video-shadow">
        </div>
        <div class="video-play-icon">
          <img src="/assets/icons/play.svg" alt="tocar"/>
        </div>
      </div>
      <div class="video-body">
        <h2>Inserir um link para o Anexo IV</h2>
      </div>
    </div>
  </div>


</div>
