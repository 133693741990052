import { Component, OnInit, ViewChild, ViewContainerRef, OnDestroy } from '@angular/core';
import { AttachmentsModel } from 'src/app/shared/models/attachments.model';
import { ScopeChangePublishFinalRequest, SummaryScopeChangePublish } from '../../interface/scope-change-publish-final';
import { FormGroup } from '@angular/forms';
import { ScopeChangeService } from '../../service/scope-change/scope-change.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { MessageService } from 'src/app/shared/common/services/message.service';
import { UserService } from 'src/app/shared/services/user.service';
import { PublishFinalFormComponent } from '../../components/scope-change-publish-final-view/publish-final-form/publish-final-form.component';
import { ProgressModalComponent } from 'src/app/modules/projects-v2/project-v2-assessment/progress-modal/progress-modal.component';
import { SuccessConfirmationComponent } from 'src/app/modules/projects-v2/project-v2-assessment/success-confirmation/success-confirmation.component';
import { Location } from "@angular/common";
import { NzUploadFile } from 'ng-zorro-antd/upload';
// import { BREADCRUMB_ACTIONS } from 'src/app/shared/ngrx/breadcrumb/breadcrumb.actions';
import { ProjectState } from 'src/app/shared/ngrx/breadcrumb/model';
import { Subject } from 'rxjs';
import { BreadcrumbState } from 'src/app/shared/ngrx/breadcrumb/breadcrumb.state.model';
import { Store } from '@ngrx/store';
import { ProjectsV2Service } from 'src/app/shared/services/projects-v2.service';
import { ScopeChangeResponse } from '../../interface/scope-change-request';
import { SCOPE_CHANGE_FINAL_ENTITY_NAME } from 'src/app/modules/projects/constants/projectsNewConstants';
import { AttachmentsService } from 'src/app/shared/services/attachments.service';
import { UtilService } from 'src/app/shared/util.service';
import { buildRoute, RoutesEnum } from 'src/app/shared/enum/routes.enum';

@Component({
  selector: 'app-scope-change-publish-final',
  templateUrl: './scope-change-publish-final.component.html',
  styleUrls: ['./scope-change-publish-final.component.scss']
})
export class ScopeChangePublishFinalComponent implements OnInit, OnDestroy {

  @ViewChild(PublishFinalFormComponent) finalForm: PublishFinalFormComponent;
  private readonly destroy$: Subject<any> = new Subject();

  summaryScopeChange: SummaryScopeChangePublish;
  attachments: AttachmentsModel[] = [];
  publishFinalPayload: ScopeChangePublishFinalRequest;
  scopeChange: ScopeChangeResponse;
  checkValidateForm: boolean = false;
  idScopeChangeParam: number;
  consolidationScopeChange: boolean = false;
  projectId: number;
  project: any;
  baseData=[]
  existAttachments: AttachmentsModel[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private modalService: NzModalService,
    private scopeChangeService: ScopeChangeService,
    private userService: UserService,
    private viewContainerRef: ViewContainerRef,
    private location: Location,
    private store: Store<BreadcrumbState>,
    private projectService: ProjectsV2Service,
    private readonly utilService: UtilService,
    private attachmentsService: AttachmentsService,
  ) { }

  ngOnInit(): void {
    this.consolidationScopeChange = this.utilService.getAuthorization('insert', 'CONSOLIDATIONSCOPECHANGE');
    this.idScopeChangeParam = +this.activatedRoute.snapshot?.params['id'];

    this.activatedRoute.parent?.params.subscribe(params => {
      this.projectId = params['projectId'];
    });

    this.getProject()
    this.getAttachment();
  }

  onScroll() {
    document.body.click();
  }

  onSaveDraft(): void {
    if (this.idScopeChangeParam && this.consolidationScopeChange) {
      this.publishFinalPayload.isDraft = true;
      this.scopeChangeService.patchPublishFinal(this.publishFinalPayload)
      .subscribe({
        next: () => {
          this.onUpload();
        },
        error: (err) => {
          let messageToShow = "Erro ao publicar o parecer";
          if (err.error && err.error.message) {
            messageToShow = err.error.message;
          } else if (err.error && err.error.errors.details) {
            messageToShow = err.error.errors.details;
          }

          this.messageService.showErrorMessage(messageToShow);
        }
      })
    }
  }

  onSave(): void {
    this.checkValidateForm = true;
    if (this.idScopeChangeParam && this.validateForm() && this.consolidationScopeChange) {
      this.publishFinalPayload.isDraft = false;
      this.scopeChangeService.patchPublishFinal(this.publishFinalPayload)
      .subscribe({
        next: () => {
          this.onUpload();
        },
        error: (err) =>{
          let messageToShow = "Erro ao salvar rascunho";
          if (err.error && err.error.message) {
            messageToShow = err.error.message;
          } else if (err.error && err.error.errors.details) {
            messageToShow = err.error.errors.details;
          }

          this.messageService.showErrorMessage(messageToShow);
        }
      })
    }
  }

  onUpload(): void {
    if (this.finalForm.uploadFiles.fileList.length > 0) {
      let modalProgressComponent = this.modalProgress();
      this.finalForm.uploadFiles.attachmentsId = this.idScopeChangeParam;
      this.finalForm.uploadFiles.uploadFilesAndUpdateProgress(this.idScopeChangeParam, modalProgressComponent);
      this.finalForm.uploadFiles.reload.subscribe(() => {
        this.modalSuccess();
      });
    } else {
      this.modalSuccess();
    }
  }

  onReturn(): void {
    this.location.back();
  }

  handleFormChanges(event: FormGroup): void {
   this.createSummary(event);
   this.createPayloadRequest(event);
   this.checkValidateForm = false;
  }

  handleAttachmentsSummary(event: NzUploadFile[]): void {
    this.attachments = [];

    this.existAttachments.forEach((elem) => {
      this.attachments.push(elem)
    });

    event.forEach((elem) => {
      this.attachments.push({ description: elem.name, name: elem.name, id: +elem.uid })
    });


  }

  validateForm(): boolean {
    if (
      this.publishFinalPayload.id &&
      this.publishFinalPayload.changeImpact.length > 0 &&
      this.publishFinalPayload.impactEstimated &&
      this.publishFinalPayload.impactDescription
    ) {
      return true;
    }
    this.messageService.showErrorMessage('Existem campos obrigatórios pendentes de preenchimento. Favor verificar.');
     return false;
  }


  createPayloadRequest(event: FormGroup) {
    let changeImpactList: string[] = [];

    if (event.controls['scopeChangeImpacts'].value) {
      changeImpactList = event.controls['scopeChangeImpacts'].value.map(elem => {return elem.description})
    }

    this.publishFinalPayload = {
      id: this.idScopeChangeParam,
      changeImpact: changeImpactList,
      impactEstimated: event.controls['impactEstimated'].value,
      impactDescription: event.controls['impactDescription'].value,
      isDraft: true
    }
  }

  createSummary(event: FormGroup): void {
    this.summaryScopeChange = {
      impactAssociate: event.controls['scopeChangeImpacts'].value,
      timeEstimate: this.filterLabelFromEstimateList(event.controls['impactEstimated'].value),
      description: event.controls['impactDescription'].value
    }
  }

  filterLabelFromEstimateList(formValue: string): string {
    return this.scopeChangeService.getEstimateList().filter(elem => elem.value === formValue)[0]?.label
  }

  modalProgress(): ProgressModalComponent {
    const modal = this.modalService.create<ProgressModalComponent>({
      nzTitle: '',
      nzClosable: false,
      nzMaskClosable: false,
      nzKeyboard: false,
      nzWidth: 555,
      nzCentered: true,
      nzContent: ProgressModalComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzFooter: null,
    });

    const instance = modal.getContentComponent();
    instance.subtitulo = 'Isso pode levar alguns minutos';
    instance.titulo = 'Fazendo upload dos arquivos...';
    instance.progress = 0;

    return instance;
  }

  getAttachment() {
    this.attachmentsService
      .getAttachmentsByEntityAndEntityId(
        this.idScopeChangeParam !== null ? +this.idScopeChangeParam : 0,
        SCOPE_CHANGE_FINAL_ENTITY_NAME)
      .subscribe((data) => {
        const nzUploadFiles = this.buildNzUploadFileArrayFromResponse(data);
        nzUploadFiles.forEach((elem) => {
          this.existAttachments.push({ description: elem.name, name: elem.name})
          this.attachments.push(this.utilService.buildAttachmentModelFromNzUpload(elem))
        });
      });
  }

  getScopeChange(event): void {
    this.scopeChange = event;
  }

  buildNzUploadFileArrayFromResponse(data: AttachmentsModel[]) {
    const files: NzUploadFile[] = [];
    for (const image of data) {
      const file: NzUploadFile = {
        description: image.description,
        name: image.name,
        filename: image.name,
        uid: image.id?.toString() || '',
        thumbUrl: this.utilService.getIconFromFileName(image.name),
        status: 'done',
      };
      files.push(file);
    }
    return files;
  }

  modalSuccess() {
    const modal = this.modalService.create<SuccessConfirmationComponent>({
      nzTitle: '',
      nzClosable: true,
      nzWidth: 555,
      nzCentered: true,
      nzContent: SuccessConfirmationComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzFooter: null,
    });

    const instance = modal.getContentComponent();

    if(this.publishFinalPayload.isDraft){
      instance.title = 'Rascunho salvo com sucesso! Você poderá retornar a esta requisição e concluí-la depois.'
      instance.subTitle = '';
    } else {
      instance.title = 'Seu parecer foi finalizado com sucesso! A análise foi enviada para a área demandante para que possa realizar a confirmação da mudança de escopo do projeto.'
      instance.subTitle = '';
    }
    modal.afterClose.subscribe(() => {
      const routeProject = buildRoute(RoutesEnum.SCOPE_CHANGE, {
        projectId: `${this.projectId}`
      });

      const routeChangePublish= buildRoute(RoutesEnum.SCOPE_CHANGE_DETAIL, {
        id: `${this.idScopeChangeParam}`
      });
      this.router.navigate([`${routeProject}/${routeChangePublish}`]);
    });

  }



  // INICIO BREADCRUMB
  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  getProject() {

    this.projectService.getProjectByProjectId(this.projectId).subscribe({
      next: (project) => {
        this.project = project;
      },
      complete: () => {
        this.dispatchProjectOnStore();
      }
    })
}

  private dispatchProjectOnStore(): void {
    // let project: ProjectState = {
    //   name: 'Projetos',
    //   url: 'projects-v2/',
    //   last: false,
    //   sublevels: true,
    //   project: {
    //     name: this.project.name,
    //     url: 'project-v2-view/' + this.project.id,
    //     last: false,
    //   },
    //   subProject: {
    //     name: 'Mudança de escopo',
    //     url: "scope-change/analysis/" + this.activatedRoute.snapshot.url[1].path,
    //     last: true,
    //   },
    //   mudancaEscopo:{
    //     name: 'Consolidação final da requisição',
    //     url: "",
    //     last: true,
    //   },
    //   parecerFinal: null,
    //   pedidoRevisao: null,
    //   comunicacoes: null,
    //   processos: null,
    //   atividades: null,
    //   subatividades: null
    // }
    // this.store.dispatch(BREADCRUMB_ACTIONS.getRiplaProject({project}));
  }

  // FINAL BREADCRUMB


}
