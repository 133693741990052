<div class="app-conversation-chat-ia">
  <ul class="messages">
    @for (message of messages; track message; let i = $index){
    <li>
      @if (i < 3 && message.role === 'human'){
        <div class="message application-sent" [class]="message.role" [innerHTML]="message.content"></div>
      } @else {
        <div class="message" [class]="message.role">
          @if (message.role === 'ai'){
            <div class="icon">
              <img src="assets/icons/speechbubble_purple32.svg" />
              <img class="particles" src="assets/icons/icon_ai_particles_dark_purple24.svg" />
            </div>
          }
  
          {{message.content}}
        </div>
      }
      @if (message.references && message.references.length > 0){
        <div class="references-container">
          <div class="references">
            <h2>{{'chat-ia.conversation.references' | translate}}</h2>
            @for (reference of message.references; track reference){
              <div class="reference">
                <span>{{'chat-ia.conversation.document' | translate}}: {{reference.filename || '-'}}</span>
                <span>{{'chat-ia.conversation.section' | translate}}: {{reference.section || '-'}}</span>
                <span>{{'chat-ia.conversation.page' | translate}}: {{reference.page || '-'}}</span>
              </div>
            }
          </div>
          <app-feedback #feedbackComponent (feedbackAction)="callAction($event, message)" [isPositive]="message.feedback?.is_positive"></app-feedback>
          
          @if(message._showFeedback && message._feedbacks && message._feedbacks.length > 0){
            <ul class="feedbacks">
              @if(message._feedbacks.length > 1){
                <li class="message-item" >
                  <div class="message" [class]="message.role">
                    <div class="icon">
                      <img src="assets/icons/speechbubble_purple32.svg" />
                      <img class="particles" src="assets/icons/icon_ai_particles_dark_purple24.svg" />
                    </div>
                    {{'chat-ia.conversation.what-needs' | translate}}
                  </div>
                </li>
              }

              @for (feedback of message._feedbacks; track feedback){
                <li [class.click]="!feedback.closeAction" (click)="this.sendFeedBack(message, feedback.content, false);">
                  {{feedback.content}}

                  @if(feedback.closeAction){
                    <a class="icon close" (click)="clearFeedbacks(i)"></a>
                  }

                </li>
              }
            </ul>
          }
        </div>
      }

      @if (message.references && message.references.length === 0) {
        <div class="references">
          <h2>{{'chat-ia.conversation.references' | translate}}</h2>
          <div class="reference">
            <span>{{'chat-ia.conversation.unable' | translate}}</span>
          </div>
        </div>
      }
    </li>
    }
    <li class="message">
      @if (openSelectionMessageField && !isSendingFiles) {
        <app-select-files-list
          #appSelectFilesListView
          [messages]="messages"
          [chatId]="chatId"
          (changeSelectedValue)="onChangeSelectedValue($event)"
          (changeCallAction)="callAction('attachments')"
          (textSentToAi)="textSentToAi($event)">
        </app-select-files-list>
      }
    </li>
    
    <li class="message">
      @if (isSendingFiles) {
        <section class="loading-documents_container">
          <strong>{{'chat-ia.conversation.loading.title' | translate}}</strong>
          <span>{{'chat-ia.conversation.loading.subtitle' | translate}}</span>

          <app-loading-bar progressionType="in_progress" [progressValue]="0" />
        </section>
      }
    </li>
  </ul>

  @if (showModalSendEmail) {
    <app-modal-confirm-send-email (changeModal)="onCloseModal($event)"></app-modal-confirm-send-email>
  }

  @if (feedbackEmail) {
    <app-modal-confirm-send-email [feedback]="feedbackEmail"></app-modal-confirm-send-email>
  }

  <div class="float-label">
    <form nz-form [formGroup]="form" nzLayout="vertical">
      <a class="icon-action attachments" (click)="callAction('attachments')">
        <span class="count" *ngIf="countAttachmentSelected > 0">{{ countAttachmentSelected }}</span>
      </a>
      <nz-form-item>
        <nz-form-control class="select-border">
          <input nz-input [placeholder]="'chat-ia.conversation.help' | translate" type="text" nzBorderless formControlName="talk" id="talk"
            class="talk" />
        </nz-form-control>
      </nz-form-item>
      <button class="icon-action send" (click)="callAction('send')" type="button"></button>
    </form>
  </div>
</div>
